import React from 'react';

export const ProfileSplash: React.FC = () => {
	return (
		<div>Log in to be awesome</div>
	);
}

export const Profile: React.FC = () => {
	return (
		<div>You are not awesome.</div>
	);
}
