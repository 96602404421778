/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://im6lvdsh5bfchltx7d2kop24gm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2ps52bsuj5d5zh4zfbccyqtgqm",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://9ndc43hlw3.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:c7ae9021-a738-46d9-bbb3-7445f75a7ddc",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Ta7eOd1d4",
    "aws_user_pools_web_client_id": "15num6m03lktusumvmf7ui0odu",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pinstrat65624-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
