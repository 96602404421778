import React from 'react';

import HttpClient from '../HttpClient';
import GameStratModel from '../models/GameStrat';
import GameMode from '../models/GameMode';
import styles from './Overview.module.css';
import Mode from './Mode';

interface OverviewSplashProps {
	name: string;
	intro: string;
	gameId: string;
}

export const OverviewSplash: React.FC<OverviewSplashProps> = (props) => {
	const introTexts:string[] = props.intro.split("\n\n");
	const titleImageUrl:string = "/images/" + props.gameId + "/overview_title.png";
	return (
		<div className={styles.splashContainer}>
			<h2>{props.name}</h2>
			<img className={styles.titleImage} src={titleImageUrl} alt="Game Logo" />
			<div>
				{ introTexts.map((t, i) => <p key={i}>{t}</p>)}
			</div>
		</div>
	)
}

interface OverviewProps {
	game: GameStratModel,
}


interface OverviewSummary {
	name: string,
	description: string,
	priority: number,
	summary: string,
}

export const Overview: React.FC<OverviewProps> = (props) => {
	const [borderImageSource, setBorderImageSource] = React.useState<string>('');
	const [backgroundImage, setBackgroundImage] = React.useState<string>('');
	const [imageBackgroundImage, setImageBackgroundImage] = React.useState<string>('');
	const [overviewModes, setOverviewModes] = React.useState<GameMode[]>([]);

	React.useEffect(() => {
		HttpClient.getImageUrl('mode_border_image.png').then(setBorderImageSource);
		HttpClient.getImageUrl('mode_background_image.png').then(setBackgroundImage);
		HttpClient.getImageUrl('mode_image_background_image.png').then(setImageBackgroundImage);
	}, []);
	React.useEffect(() => {
		setOverviewModes(props.game.overview.summaries.map(
			(sum:OverviewSummary) => new GameMode(props.game.id, sum)
		))
	}, [props.game])

	return (
		<div>
			{overviewModes.map((sum) => {
				return (
					<Mode mode={sum}
						borderImageSource={borderImageSource}
						backgroundImage={backgroundImage}
						imageBackgroundImage={imageBackgroundImage}
					/>
				)
			})}
		</div>
	)
}
