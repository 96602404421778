import GameMode from './GameMode';
import HttpClient from '../HttpClient';

class GameStrat {
	id: string;
	rev: string;
	name: string;
	ballTime: number;
	modes: GameMode[];
	overview: any;
	score: number;
	_fetchProm: Promise<any>;
	constructor() {
		this.id = "";
		this.rev = "";
		this.name = "";
		this.modes = [];
		this.overview = {};
		this.score = 0;
		this.ballTime = 0.0;

		this._fetchProm = this.fetch();
	}

	fetch() {
		return HttpClient.getGameData().then((gameData: any) => {
			  this.id = gameData.gameId;
			  this.rev = gameData.rev;
	          this.name = gameData.name;
	          this.overview = gameData.overview;
			  this.score = parseInt(gameData.score);
			  this.ballTime = parseInt(gameData.ballTime);
	          this.modes = gameData.modes.map((mode: any) => new GameMode(this.id, mode));
	        });

	}

	onReady() {
		return Promise.resolve(this._fetchProm);
	}
}

export default GameStrat;
