
import { Amplify } from 'aws-amplify';
import { get } from 'aws-amplify/api';
import { getUrl } from 'aws-amplify/storage';
import awsconfig from './aws-exports';

class HttpClient {
    private static _instance:HttpClient;
    public gameId: string;
    private gameRev: string;
    private hashCode: string;
    private static isLocal: boolean = window.location.hash === "#local";

    public constructor () {
        Amplify.configure(awsconfig);
        this.hashCode = window.location.search.substring(1)
        this.gameId = this.hashCode.substring(0,2);
        this.gameRev = this.hashCode.substring(2,3);
    }

    public static get Instance()
    {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }

    private _getImageRemote(image: string) {
        return getUrl({
            key: `games/${this.gameId}/images/${image}`
        }).then(result => {
            return result.url.toString()
        })
    }
    private _getImageLocal(image: string) {
        return Promise.resolve(`/images/${this.gameId}/${image}`)
    }

    public getImage: (image: string, preload?: boolean) => Promise<string> = (image, preload) => {
        const fetchProm = HttpClient.isLocal ?
            this._getImageLocal(image) : this._getImageRemote(image);

        if (!preload) return fetchProm;
        return fetchProm.then((url: string) => new Promise<string>(resolve => {
            var preloadImage = document.createElement("img");
            preloadImage.onload = () => {
                resolve(url);
            }
            preloadImage.src = url;
        }));
    }

    public getImageUrl (image: string) {
        return this.getImage(image).then(url => `url("${url}")`);
    }

    private _getGameCssRemote() {
        return getUrl({
            key: `games/${this.gameId}/${this.gameId}.css`
            // contentType: "text/css"
        }).then(result => result.url.toString());
    }
    private _getGameCssLocal() {
        return Promise.resolve(`/games/${this.gameId}/${this.gameId}.css`)
    }

    public getGameCss: () => Promise<string> =
        HttpClient.isLocal ? this._getGameCssLocal : this._getGameCssRemote;

    private _getGameDataRemote() {
        return get({
            apiName: 'api',
            path: `/strat/${this.hashCode}`
        }).response.then(res => res.body.json());
    }

    private _getGameDataLocal() {
        const re = /(?<gameData>([1-9].{2})+)+0(?<score>\d+?)(?<time>\d{4})$/
        let match = this.hashCode.match(re);
        let params: string[][];
        let parsedCode: any;
        if (match !== null && match.groups !== undefined) {
            parsedCode =  [ match.groups.gameData, match.groups.score, match.groups.time ]
            const paramString:RegExpMatchArray|null = parsedCode[0].substring(3).match(/.{3}/g);
            if (paramString !== null) {
                params = paramString.map(i => [i.substring(0,2), i[2]]);
            }
        }

        const localReq = new Request(`/games/${this.gameId}/${this.gameId}.${this.gameRev}.json`)
        return window.fetch(localReq).then(response => response.json()).then(gameData => {
            // If no params provided, fallback to just the game info layout
            if (!params) { return gameData; }

            const desiredModes = params.map(p => p[0]);
            var newModes = new Array(desiredModes.length);
            gameData.modes.forEach((mode:any) => {
                const idx = desiredModes.indexOf(mode["key"]);
                if (idx >= 0) {
                    mode.status = mode.statuses[parseInt(params[idx][1])]
                    if (!mode.status) {
                        return;
                    }
                    delete mode.statuses
                    newModes[idx] = mode;
                }
            });
            gameData.modes = newModes;
            gameData.modes.sort((a:any, b:any) => desiredModes.indexOf(a.key) - desiredModes.indexOf(b.key));
            gameData.score = parsedCode[1];
            gameData.ballTime = parsedCode[2];
            return gameData;
        });
    }

    public getGameData: () => Promise<any> =
        HttpClient.isLocal ? this._getGameDataLocal : this._getGameDataRemote;
}

export default HttpClient.Instance;