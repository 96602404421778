import React from 'react';
import { Fade } from '@mui/material';

import { Analysis, AnalysisSplash } from './components/Analysis';
import { Overview, OverviewSplash } from './components/Overview'
import { Profile, ProfileSplash } from './components/Profile';
import MenuBar from './components/MenuBar';

import GameStratModel from './models/GameStrat';
import styles from './App.module.css';
import HttpClient from './HttpClient';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
const DEFAULT_VIEW = "analysis";
const FALLBACK_VIEW = "overview";

Amplify.configure(awsconfig);

async function fetchCss() {
  const file = await HttpClient.getGameCss();
  const fileref = document.createElement("link");
  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", file);
  document.head.appendChild(fileref);
}

const App: React.FC = () => {
  const [game, setGame] = React.useState<GameStratModel | null>();
  const [view, setView] = React.useState<string>(DEFAULT_VIEW);
  const [ready, setReady] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (HttpClient.gameId) {
      fetchCss();
      const gstrat = new GameStratModel();
      // Preload the top images so they are ready to fade in
      Promise.all([
        gstrat.onReady(),
        HttpClient.getImage('background.png', true),
        HttpClient.getImage('game_logo.png', true),
      ]).then(results => {
        setGame(gstrat);
      });
    } else {
      // If there is no game, fail out
      setReady(true);
    }
  }, [] // Empty array on useEffect means only on componentDidMount
  );

  React.useEffect(() => {
    if (game) {
      if (!game.score) {
        setView(FALLBACK_VIEW)
      }
      setReady(true)
      document.title = `PinStrat: ${game.name}`;
    }
  }, [game]);

  if (!game) {
    return (
        <Fade in={ready} timeout={500}>
        <div className={`emptyHero ${styles.emptyApp}`}>
          <div className={styles.emptyCopy}>PinStrat &copy; 2020-2022 Paradigm Tilt LLC<br/>US Patent Pending 17/510,750</div>
        </div>
        </Fade>
    );
  }

  if (!ready) {
    return (
      <Fade in={true} timeout={200}>
        <div className={styles.app}></div>
      </Fade>
    )
  }

  let splashChild, bodyChild;
  switch (view) {
    case "overview":
      bodyChild = <Overview game={game} />;
      splashChild = <OverviewSplash name={game.name} gameId={game.id} intro={game.overview.intro} />
      break
    case "profile":
      splashChild = <ProfileSplash />;
      bodyChild = <Profile />;
      break;
    case "analysis":
      const score:string = game.score.toLocaleString();
      const ballTime:number = game.ballTime;
      splashChild = <AnalysisSplash score={score} ballTime={ballTime} gameId={game.id} />;
      bodyChild = <Analysis modes={game.modes} gameId={game.id} />;
      break;
    default:
      throw new Error("Unknown view selection '" + view +"'");
  }

  return (
    <Fade in={true} timeout={500}>
    <div className={styles.app} >
      {splashChild}
      {/* <MenuBar title={game.name} onSelect={setView} showStrategy={!!game.score} /> */}
      <div className={styles.appScrollContainer + " globalAppBorderColor"}>
        <div className={styles.appContent}>
          {bodyChild}
        </div>
      </div>
    </div>
    </Fade>
  );
}

export default App;
