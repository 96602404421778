class GameMode {
  key?: string;
  name: string;
  category?: string | undefined;
  color?: string | undefined;
  description: string;
  image?: string | undefined;
  summary: string;
  priority: number;
  status?: any;
  _data: any;
  constructor(gameId: string, modeData:any, status?:string) {
    this.key = modeData.key || modeData.name;
    this.name = modeData.name;
    this.category = modeData.category;
    this.color = (modeData.color && modeData.color.match(/^[A-Fa-f0-9]{6}$/)) ? "#" + modeData.color : undefined;
    this.description = modeData.description;
    this.image = modeData.image;
    this.summary = modeData.summary;
    this.priority = modeData.priority;
    this.status = status !== undefined ? modeData.statuses[status] : modeData.status;
    this._data = modeData;
  }
}

export default GameMode;
