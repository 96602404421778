import React from 'react';

import GameModeModel from '../models/GameMode';
import Mode from './Mode';
import styles from './Analysis.module.css';
import HttpClient from '../HttpClient';

interface AnalysisSplashProps {
	score: string;
  ballTime: number;
  gameId: string;
}

export const AnalysisSplash: React.FC<AnalysisSplashProps> = (props) => {
  const [ gameLogo, setGameLogo ] = React.useState<string>();
  const [backgroundImage, setBackgroundImage] = React.useState<string>();

  React.useEffect(() => {
    Promise.all([
      HttpClient.getImage('background.png'),
      HttpClient.getImage('game_logo.png')
    ]).then(urls => {
      setBackgroundImage(`url("${urls[0]}")`);
      setGameLogo(urls[1]);
    });
  }, []);
  const ballMins = Math.floor(props.ballTime / 60);
  const ballSecs = props.ballTime - (ballMins * 60);
  const ballTimeString:string =
    (ballMins > 0 ? ballMins + " min" + (ballMins === 1 ? " " : "s ") : "") +
    ballSecs + " sec" + (ballSecs === 1 ? "" : "s");

	return (
		<div className={styles.splash} style={{ backgroundImage }}>
      <img className={styles.splashLogo} src={gameLogo} alt="Game Logo"/>
      {props.score !== undefined && (
        <div className={styles.splashScore}>
          <div className={styles.splashScoreTitle}>Your Score:</div>
          <div className={styles.splashScoreValue + " globalFontFamily"}>{props.score}</div>
        </div>
      )}
      {props.ballTime !== undefined && (
        <div className={styles.splashScoreBallTime}>
          <div>Total Ball Time:</div>
          <div>{ballTimeString}</div>
        </div>
      )}
      <div className={styles.splashInstructions}>
        SCROLL FOR YOUR PERSONALIZED STRATEGY GUIDE
      </div>
    </div>
  )
}

interface AnalysisProps {
  gameId: string;
  modes: GameModeModel[];
};

export const Analysis: React.FC<AnalysisProps> = (props) => {
  const [borderImageSource, setBorderImageSource] = React.useState<string>('');
  const [backgroundImage, setBackgroundImage] = React.useState<string>('');
  const [imageBackgroundImage, setImageBackgroundImage] = React.useState<string>('');

  React.useEffect(() => {
    HttpClient.getImageUrl('mode_border_image.png').then(setBorderImageSource);
    HttpClient.getImageUrl('mode_background_image.png').then(setBackgroundImage);
    HttpClient.getImageUrl('mode_image_background_image.png').then(setImageBackgroundImage);
  }, []);
	return (
		<div>
      {props.modes.map(mode => { return (
        <div className={styles.mode} key={mode.key} >
          <Mode mode={mode}
            gameId={props.gameId}
            borderImageSource={borderImageSource}
            backgroundImage={backgroundImage}
            imageBackgroundImage={imageBackgroundImage}
          />
        </div>
      )})}
    </div>
	)
}
