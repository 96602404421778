import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { StylesProvider } from '@material-ui/styles';

import GameMode from '../models/GameMode';
import styles from './Mode.module.css';
import HttpClient from '../HttpClient';

interface ModeProps {
  gameId?: string;
  mode: GameMode;
  borderImageSource: string;
  backgroundImage: string;
  imageBackgroundImage?: string;
}

const Mode: React.FC<ModeProps> = (props) => {
  const [ isExpanded, onToggleExpand ] = React.useState<boolean>(false);
  const [ imageSource, setImageSource ] = React.useState<string>();

  React.useEffect(() => {
    HttpClient.getImage(props.mode.image as string).then(url => setImageSource(url));
  }, [props.mode.image])

  if (!props.mode) {
    return null;
  }

  const { category, color, description, name, status, summary } = props.mode;
  const { borderImageSource, backgroundImage, imageBackgroundImage } = props;

  const summaryTexts:string[] = summary.split("\n\n");
  const instructionTexts:string[] = status.instructions.split("\n\n");
  const descriptionTexts:string[] = description.split("\n\n");
  return (
    <div className={`${styles.modeContainer} globalModeContainer`}
      style={{ borderImageSource, backgroundImage, borderColor: color }}>
        { category && (
          <div className={styles.modeCategory}>{category}</div>
        )}
        <div className={styles.modeTitle + " globalFontFamily globalModeTitle"}>{name}</div>
        {imageSource && (
            <div className={styles.modeImageContainer + " globalModeImageContainer"}
              style={{ backgroundImage: imageBackgroundImage }}
              >
                <img className={styles.modeImage + " globalModeImage"} src={imageSource} alt="Mode"/>
            </div>
        )}
        <div className={styles.modeContent}>
            <div className={`${styles.modeSummary} globalModeSummary`}>
              { summaryTexts.map((t, i) => <p key={i}>{t}</p>)}
            </div>
            <div className={styles.modeStatus + " globalFontFamily globalModeStatus"}>{status.name}</div>
            <div>
              { instructionTexts.map((t, i) => <p key={i}>{t}</p>)}
            </div>
            { descriptionTexts && (
                // <StylesProvider injectFirst>
                  <Accordion expanded={isExpanded} className={styles.modeDescriptionContainer}>
                      <AccordionSummary className={styles.modeDescriptionToggle + " globalFontFamily globalModeDescriptionToggle"}
                          expandIcon={<ExpandMoreIcon className={styles.modeDescriptionIcon + " globalModeDescriptionIcon"} />}
                          onClick={() => onToggleExpand(!isExpanded)}>
                          INSTRUCTIONS
                      </AccordionSummary>
                      <AccordionDetails className={styles.modeDescriptionText}>
                          <div>{ descriptionTexts.map((t, i) => <p key={i}>{t}</p>)}</div>
                      </AccordionDetails>
                  </Accordion>
                // </StylesProvider>
            )}
        </div>
    </div>
  )
}

export default Mode
